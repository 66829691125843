import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import { InputText } from '~/components/NovosInputs';
import ToggleDefault from '~/components/ToggleDefault';

import { useTela } from '../../TelaContext';
import { TipoAgregadorContainer } from './components/Containers/TipoAgregadorContainer';
import { TipoDadosContainer } from './components/Containers/TipoDadosContainer';
import { TipoInformacaoContainer } from './components/Containers/TipoInformacaoContainer';
import { InputWidth } from './components/InputWidth';
import { Sinonimos } from './components/Sinonimos';

export const ModalFormCampo: React.FC = () => {
  const {
    formCampo,
    isUpdateCampo,
    isShowModalCampo,
    setIsShowModalCampo,
    formTela,
    generateIdCampo,
    isUpdate,
  } = useTela();

  const {
    register,
    formState,
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    setError,
  } = formCampo;

  const { setValue: setValueFormTela, getValues: getValuesFormTela } = formTela;

  const watchFlgFiltro = watch('flg_filtro');
  const watchFlgVisualizacao = watch('flg_visualizacao');
  const watchFlgFiltroObrigatorio = watch('flg_filtro_obrigatorio');

  useEffect(() => {
    if (!watchFlgFiltro) {
      setValue('flg_filtro_obrigatorio', false);
    }
  }, [watchFlgFiltro]);

  useEffect(() => {
    if (!watchFlgVisualizacao) {
      setValue('tipo_agregador_tot', { label: undefined, value: undefined });
    }
  }, [setValue, watchFlgVisualizacao]);

  const { errors } = formState;

  const watchCodTipoDados = watch('cod_tipo_dados');

  const [minValue, setMinValue] = useState(80);
  const [maxValue, setMaxValue] = useState(300);

  const defaultValues = {
    flg_filtro: false,
    flg_visualizacao: false,
    flg_filtro_obrigatorio: false,
    val_largura: 100,
    cod_tipo_dados: { label: undefined, value: undefined },
    cod_tipo_info: { label: undefined, value: undefined },
    tipo_agregador_tot: { label: undefined, value: undefined },
    des_campo: '',
    nome_bd: '',
    sinonimos: [],
  };

  useEffect(() => {
    if (watchCodTipoDados && watchCodTipoDados.value) {
      if ([3, 4].includes(watchCodTipoDados.value)) {
        setMinValue(80);
        setMaxValue(300);
      } else {
        setMinValue(100);
        setMaxValue(950);
      }
    }
  }, [watchCodTipoDados]);

  const handleCloseModal = () => {
    setIsShowModalCampo(false);
    reset(defaultValues, {
      keepDirty: false,
      keepErrors: false,
      keepIsSubmitted: false,
      keepDirtyValues: false,
      keepIsValid: false,
    });
  };

  useEffect(() => {
    setValue('flg_filtro', false);
    setValue('flg_visualizacao', false);
    setValue('flg_filtro_obrigatorio', false);
  }, []);

  const onCancelClick = () => {
    handleCloseModal();
  };

  const onConfirmClick = async () => {
    await handleAddCampo();
  };

  const handleAddCampo = handleSubmit(async () => {
    let form = getValues();
    const campos = getValuesFormTela('campos');
    let nomesBdCampos: string[] = [];
    let nomesCampos: string[] = [];
    campos.map((campo: any) => {
      nomesBdCampos = [...nomesBdCampos, campo.nome_bd];
      nomesCampos = [...nomesCampos, campo.des_campo];
      return campo;
    });

    if (!isUpdate) {
      if (isUpdateCampo) {
        const newValues = campos.map((item: any) => {
          if (item.id === form.id) {
            item = form;
          }
          return item;
        });

        setIsShowModalCampo(false);
        reset(defaultValues);
        return setValueFormTela('campos', newValues);
      }
      if (nomesCampos.indexOf(form.des_campo) > -1) {
        setError('des_campo', {});
        return toast.warning('Já existe um nome com essa descrição.');
      }
      if (nomesBdCampos.indexOf(form.nome_bd) > -1) {
        setError('nome_bd', {});
        return toast.warning('Já existe um nome_bd com essa descrição.');
      }
      const id = nanoid();

      form = { ...form, id, num_ordem: campos.length };
      setValueFormTela('campos', [...campos, form]);
    } else {
      if (isUpdateCampo) {
        const newValues = campos.map((item: any) => {
          if (item.id === form.id) {
            item = form;
          }
          return item;
        });
        setIsShowModalCampo(false);
        reset(defaultValues);
        return setValueFormTela('campos', newValues);
      }

      if (nomesBdCampos.indexOf(form.nome_bd) > -1) {
        return toast.warning('Já existe um nome_bd com essa descrição.');
      }

      const id = nanoid();
      form = { ...form, id, uuid: id, num_ordem: campos.length };
      setValueFormTela('campos', [...campos, form]);
    }

    setIsShowModalCampo(false);
    reset(defaultValues);
  });

  return (
    <Modal show={isShowModalCampo} onHide={handleCloseModal} size="xl">
      <Modal.Header>
        <Modal.Title>
          {isUpdateCampo ? 'Editar campo' : 'Adicionar campo'}
        </Modal.Title>
        <button onClick={handleCloseModal} type="button" className="btn-close">
          {}
        </button>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col sm={12} lg={6}>
              <InputText
                label="Nome do campo"
                maxLength={100}
                placeholder="Insira o nome do campo"
                name="des_campo"
                register={register}
                disabled={false}
                isError={!!errors.des_campo}
                autoComplete="off"
                control={control}
              />
            </Col>
            <Col sm={12} lg={6}>
              <InputText
                label="Nome do campo no BD"
                maxLength={100}
                placeholder="Insira o nome do campo no BD"
                name="nome_bd"
                register={register}
                toLowerCase
                disabled={false}
                isError={!!errors.nome_bd}
                autoComplete="off"
                control={control}
              />
              <Form.Text className="text-muted">
                Os nomes devem iniciar com &quot;t.&ldquo;
              </Form.Text>
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <TipoDadosContainer />
            </Col>
            <Col sm={12} lg={6}>
              <TipoInformacaoContainer />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={4} sm={4} lg={3} xl={2}>
              <ToggleDefault
                labelText="Disponível p/ filtro?"
                setChecked={watchFlgFiltro}
                onSwitch={() => setValue('flg_filtro', !watchFlgFiltro)}
              />
            </Col>
            <Col xs={4} sm={4} lg={3} xl={2}>
              <ToggleDefault
                labelText="Disponível p/ visualização"
                setChecked={watchFlgVisualizacao}
                onSwitch={() =>
                  setValue('flg_visualizacao', !watchFlgVisualizacao)
                }
              />
            </Col>
            <Col sm={4} lg={3} xl={2}>
              {watchFlgFiltro && (
                <ToggleDefault
                  labelText="Filtro obrigatório"
                  setChecked={watchFlgFiltroObrigatorio}
                  onSwitch={() =>
                    setValue(
                      'flg_filtro_obrigatorio',
                      !watchFlgFiltroObrigatorio,
                    )
                  }
                />
              )}
            </Col>

            {watchFlgVisualizacao && (
              <Col sm={12} lg={6}>
                <TipoAgregadorContainer />
              </Col>
            )}
          </Row>

          <Row className="mt-2">
            <Col xs={12}>
              <Sinonimos />
            </Col>
          </Row>

          <Row className="mt-2 d-flex align-items-center">
            {/* <Col sm={6} lg={2}>
              <InputNumber
                min={minValue}
                max={maxValue}
                maxLength={99999999}
                label="Largura"
                placeholder="0"
                name="val_largura"
                register={register}
                disabled={false}
                control={control}
                isError={!!errors.val_largura}
              />
            </Col>
            <Col sm={6} lg={4}>
              <div
                style={{
                  marginTop: '2rem',
                }}
              >
                <InputRange />
              </div>
            </Col> */}
            <InputWidth />
          </Row>

          <Row className="mt-4">
            <Col xs={12}>
              <div className="d-flex align-items-center justify-content-end">
                <CancelButton
                  onClick={() => {
                    onCancelClick();
                  }}
                >
                  Cancelar
                </CancelButton>
                <ConfirmButton
                  onClick={() => {
                    onConfirmClick();
                  }}
                >
                  Confirmar
                </ConfirmButton>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
